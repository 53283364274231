@font-face {
    font-family: "KT Alpha";
    src: url("../fonts/KT-Alpha-Regular.woff2") format("woff2");
    font-display: swap;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scrollbar-width: thin;
    scrollbar-color: var(--color-primary) transparent;
}

:root {
    --bg: #111116;
    --bgt: #0004;
    --color-grad-1: #212232;
    --color-grad-2: #171820;
    --color-text: white;
    --color-input-text: #b3b4cc;
    --color-primary: #68c4ff;
    --color-glow-1: #60c8ff;
    --color-glow-2: #52ebfb;
    --color-glow-3: #2dabff;
    --color-glow-4: #1874ff;
    --light-color: #b8bdf944;
    --color-underline: #282a38;
    --container-margin: 250px;
    --xxxl-gap: 160px;
    --xxl-gap: 80px;
    --xl-gap: 60px;
    --lg-gap: 40px;
    --md-gap: 20px;
    --sm-gap: 15px;
    --xs-gap: 10px;
    --blur-amount: 40px;
    --bullet-spacing: 2em;

    --fs-reg: 0.9375em;
    --fs-h1: 3.5em;
    --fs-h2: 2.5em;

    --tl-card-width: 300px;
    --grad-blue-glow: linear-gradient(
        to right,
        transparent 5%,
        var(--color-glow-3),
        var(--color-glow-1),
        var(--color-glow-3),
        transparent 95%
    );
    --grad-fade-underline: linear-gradient(
        to right,
        transparent 5%,
        #292a3d,
        transparent 95%
    );
    --grad-radial: radial-gradient(
        circle at right top,
        var(--color-grad-1) 0%,
        var(--color-grad-2) 80%
    );
    --grad-blue-radial: radial-gradient(
        circle at right top,
        var(--color-primary) 0%,
        #5ab1fb 80%
    );
    scroll-behavior: smooth;
}

html,
body {
    width: 100%;
    overscroll-behavior: none;
    min-height: 100vh;
}

body {
    font-family: Jost, sans-serif;
    font-size: var(--fs-reg);
    letter-spacing: 0.1em;

    overflow-x: hidden;
    background: var(--bg);
    color: var(--color-text);
    font-weight: 400;
    justify-content: space-between;
}

#canvas {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    z-index: -1;
    pointer-events: none;
}

h1,
h2 {
    font-weight: 500;
    line-height: 1.3;
    margin-bottom: var(--lg-gap);
}

h1 {
    font-size: var(--fs-h1);
    margin-bottom: var(--xl-gap);
}

h2 {
    font-size: var(--fs-h2);
    text-transform: uppercase;
}

h1,
h2 {
    font-family: "KT Alpha", "Jost";
}

ul {
    list-style-type: none;
}

section li {
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--sm-gap);
}

b {
    font-weight: 500;
}

em {
    font-weight: 500;
    color: var(--color-primary);
    font-style: initial;
}

a {
    text-decoration: none;
}

.small-font {
    font-size: 0.75em !important;
}

.center-text {
    text-align: center;
}

.container-inner li {
    display: flex;
    align-items: flex-start;
}

.container-inner li .point {
    margin-right: 0.5em;
    flex-shrink: 0;
}

.point {
    color: var(--color-glow-1);
    font-weight: 400;
    height: var(--bullet-spacing);
    width: var(--bullet-spacing);
    text-shadow: 0 0 2px var(--color-glow-2), 0 0 4px var(--color-glow-2),
        0 0 8px var(--color-glow-3), 0 0 16px var(--color-glow-4),
        0 0 24px var(--color-glow-4), 0 0 32px var(--color-glow-4),
        0 0 64px var(--color-glow-4);
}

.ionicon {
    width: 24px;
    height: 24px;
    stroke: white !important;
}

.bs-icon {
    width: 24px;
    height: 24px;
}

button {
    background-color: transparent;
    border-color: transparent;
    padding: 0;
    margin: 0;
    border: none;
}

body,
#side-menu,
#side-menu ul,
.timeline-card,
.card-text-content,
.form-group,
.form-section,
.sm-form-group,
.notification,
.footer-section {
    display: flex;
    flex-direction: column;
}

/****************************************
* CONTAINERS
*****************************************/

.container {
    width: 100%;
    margin: var(--container-margin) auto;
}

.container-inner {
    width: 80%;
    max-width: 1440px;
    margin: 0 auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.col-half {
    flex: 0 0 50%;
    max-width: 50%;
}

.col-third {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
}

.col-quater {
    flex: 0 0 25%;
    max-width: 25%;
}

.col-half img {
    max-width: 100%;
}

.col-third img {
    max-width: 60%;
}

.col-quater img {
    max-width: 85%;
}

.center-items {
    align-items: center;
}

.mb-md {
    margin-bottom: var(--md-gap);
}

.mb-lg {
    margin-bottom: var(--lg-gap);
}

.mb-xl {
    margin-bottom: var(--xl-gap);
}

.mt-xxl {
    margin-top: var(--xxl-gap);
}

.mt-sm {
    margin-top: var(--sm-gap);
}

.mb-xxl {
    margin-bottom: var(--xxl-gap);
}

.separated {
    position: relative;
}

.separated-gap-sm {
    gap: 0;
}

.d-flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.align-items-center {
    align-items: center;
}

.align-items-end {
    align-items: end;
}

.space-between {
    justify-content: space-between;
}

.no-display {
    display: none;
}

.visually-hidden {
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

/****************************************
* NAVIGATION
*****************************************/

#navbar,
.no-js nav,
footer,
.no-js footer {
    display: flex;
    width: 80%;
    max-width: 1440px;
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
    gap: var(--lg-gap);
    z-index: 1;
}

#navbar {
    z-index: 100;
    top: 0;
    transition: top 0.3s ease;
}

#navbar,
.no-js nav,
.no-js footer {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
}

#navbar.hide {
    top: -92px;
}

.no-js nav::before,
.no-js footer::before {
    content: "";
    position: absolute;
    top: 0px;
    left: -20px;
    right: -20px;
    bottom: -6px;
    z-index: -1;
}

.no-js nav::before {
    background: url("../images/svgs/nav-1800.svg") no-repeat center center;
    background-size: 100% 92px;
}

footer {
    position: relative;
    padding: 20px;
    margin: var(--container-margin) auto 0;
}

.no-js footer {
    position: relative;
    padding: 20px;
    margin: 0;
}

.no-js footer::before {
    background: url("../images/svgs/footer-1800.svg") no-repeat center center;
    background-size: 100% 197px;
}

#nav-shape-container,
#footer-shape-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: -1;
}

#footer-shape {
    width: 100%;
    height: 100%;
}

.right-nav,
.left-nav {
    display: flex;
    align-items: center;
    gap: var(--lg-gap);
}

.right-nav {
    margin-left: auto;
}

.left-nav {
    gap: 5px;
}

#navbar a {
    color: var(--color-text);
    gap: var(--lg-gap);
    transition: color ease 0.4s, text-shadow ease 0.4s;
}

.kt-logo {
    height: 60px;
    width: 92px;
}

.kt-title {
    height: 32px;
    width: 89px;
}

#menu-btn {
    display: none;
    cursor: pointer;
    padding: 15px;
}

.menu-checkbox {
    display: none;
}

#menu-checkbox:checked ~ #side-menu {
    right: 0;
    opacity: 1;
    box-shadow: -10px 0px 100px #459ff744;
    transition: right 0.3s ease-in-out, box-shadow 2s ease-in-out;
}

#menu-checkbox ~ #side-menu {
    right: -100%;
    opacity: 1;
}

#menu-checkbox:checked ~ #menu-btn,
#menu-checkbox:checked ~ #close-btn {
    --aria-expanded: true;
}

#menu-btn,
#close-btn {
    content: attr(aria-label) " "
        attr(aria-expanded, var(--aria-expanded, false));
}

.top-bar {
    background: var(--grad-radial);
    padding: 5px;
    text-align: center;
    position: absolute;
    width: 100%;
    top: 96px;
    left: 0;
    border-radius: 5px;
}

.top-bar::after {
    content: "";
    border: 1px solid var(--light-color);
    width: 100%;
    height: calc(100% + 2px);
    border-radius: 5px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
}

.top-bar:hover::after {
    border: 1px solid var(--color-primary);
}

/****************************************
* SIDE MENU
*****************************************/

#side-menu {
    position: fixed;
    right: -100%;
    width: 30%;
    height: 100%;
    justify-content: space-evenly;
    transition: right 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    z-index: 999;
    will-change: scroll-position;
    opacity: 0;
    overflow: hidden;
    gap: 20px;
    padding: 0 10%;
    background: var(--color-grad-2);
    box-shadow: -10px 0px 100px #459ff700;
    top: 0;
    bottom: 0;
}

#side-menu-left,
#side-menu-right {
    width: 7.5%;
    height: 100%;
    position: absolute;
}

#side-menu-left {
    left: 0;
}

#side-menu-right {
    right: 0;
    transform: scaleX(-1);
}

#close-btn {
    position: absolute;
    top: 0;
    right: 10%;
    padding: 20px;
}

#side-menu ul {
    gap: var(--md-gap);
    height: calc(100% - 74px * 2);
    justify-content: space-between;
}

#side-menu li,
#side-menu a {
    align-self: center;
}

#side-menu a {
    color: var(--color-text);
    text-decoration-line: none;
    font-size: 1.5em;
    transition: color ease 0.4s, text-shadow ease 0.4s;
}

/****************************************
* HERO SECTION
*****************************************/

#hero {
    display: flex;
    align-items: center;
    background-color: var(--bg);
    background-image: url("../images/bg.webp"), url("../images/bg1.png");
    background-size: cover;
    background-position: center;
    min-height: 1080px;
}

#hero .right-section img {
    max-width: 50%;
}

svg {
    overflow: visible;
}

#hero-text {
    font-size: 2em;
    line-height: 3;
}

.hero-text-section {
    position: absolute;
    top: 195px;
    width: 80%;
    flex-direction: column;
}

.border-bottom-glow {
    border-bottom: 2px solid transparent;
    border-image: var(--grad-blue-glow) 1;
}

.border-bottom-underline {
    border-bottom: 2px solid transparent;
    border-image: var(--grad-fade-underline) 1;
}

/****************************************
* ACCELERATION SECTION
*****************************************/

.accel-list p:first-of-type {
    min-width: 150px;
}

/****************************************
* TIMELINE
*****************************************/

.card {
    position: relative;
    background: var(--grad-radial);
    width: 60%;
    margin: auto;
    padding: 10px;
    border-radius: 8px;
}

#orbit-anim {
    border-radius: 0 !important;
}

.col-half .card {
    margin-top: 60px;
}

.card-border {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    pointer-events: none;
}

.timeline {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;
    justify-content: space-around;
    overflow: hidden;
}

.no-js #tl-instruct {
    display: none;
}

.timeline-card {
    --offset: 0;
    width: var(--tl-card-width);
    transform-origin: bottom;
    bottom: 0;
    outline: none;
    transition: transform 0.5s ease, left 0.5s ease, opacity 0.25s ease;
}

.timeline-card,
.card-text-content {
    gap: var(--md-gap);
}

.card-text-content {
    transition: opacity 0.5s ease;
}

.card-image {
    align-self: center;
    height: 300px;
    margin-top: auto;
}

.card-image img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

.no-js .timeline-card {
    opacity: 0.5;
    transform: scale(0.8);
}

.no-js .timeline-card:focus {
    opacity: 1;
    transform: scale(1);
    outline: none;
}

.dotted {
    stroke: rgba(255, 255, 255, 0.5) !important;
    stroke-linecap: round;
    stroke-dasharray: 8, 10;
}

#arrow1,
#arrow2,
#arrow3 {
    opacity: 0;
}

.show-arrows #arrow1 {
    animation: flash 1s infinite;
    -webkit-animation: flash 1s infinite;
}

.show-arrows #arrow2 {
    animation: flash 1s infinite 0.25s;
    -webkit-animation: flash 1s infinite 0.25s;
}

.show-arrows #arrow3 {
    animation: flash 1s infinite 0.5s;
    -webkit-animation: flash 1s infinite 0.5s;
}

@keyframes flash {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

#axis .domain {
    stroke-width: 0;
}

.tl-major-tick,
.tl-inner-point,
.tl-minor-tick,
.tl-outer-point {
    stroke: #333449;
}

.tl-major-tick,
.tl-inner-point {
    fill: #333449;
}

.tl-outer-point {
    fill: none;
    stroke-width: 2;
}

.tl-minor-tick {
    stroke-width: 2;
}

[id^="tickGroup"] {
    cursor: pointer;
}

[id^="tickGroup"] circle,
[id^="tickGroup"].active-point circle {
    transition: cx 0.5s;
}

[id^="tickGroup"]:hover .tl-outer-point,
[id^="tickGroup"].active-point .tl-outer-point {
    stroke: #5dbcff;
    filter: url(#glow1);
}

[id^="tickGroup"]:hover .tl-inner-point,
[id^="tickGroup"].active-point .tl-inner-point {
    stroke: #5dbcff;
    fill: #5dbcff;
    filter: url(#glow1);
}

/****************************************
* ABOUT US
*****************************************/

.profile-section {
    display: flex;
    gap: var(--xxl-gap);
    align-items: stretch;
    height: 525px;
}

.profile-section:first-of-type,
.profile-section:nth-of-type(2) {
    margin-bottom: var(--xxl-gap);
}

.profile-section img {
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    max-width: 25%;
    flex: 0 0 25%;
}

.profile-section h3 {
    font-size: 1.75em;
}

.profile-details h4 {
    font-weight: 400;
    font-size: 1.25em;
}

/****************************************
* CONTACT FORM
*****************************************/

#contact {
    scroll-margin-top: 150px;
}

.form-container {
    width: 50%;
    margin: 0 auto;
}

.form-group {
    position: relative;
    gap: 10px;
}

.form-group:last-of-type {
    margin-bottom: 40px;
}

label {
    display: block;
    cursor: pointer;
    font-weight: 400;
    font-size: 1.2em;
}

.form-section {
    gap: var(--lg-gap);
}

.form-input {
    min-height: 50px;
    font-size: 1em;
    letter-spacing: 0.1em;
    background: var(--grad-radial);
    padding: 10px;
    width: 100%;
    overflow: hidden;
    border: none;
    border-radius: 4px;
    transition: height 0.3s ease-in-out;
    caret-color: white;
    color: var(--color-input-text);
    border-bottom: 2px solid transparent;
    border-image: var(--grad-fade-underline) 1;
}

.form-input:focus {
    border-image: var(--grad-blue-glow) 1;
    outline: none;
}

.notification-btn,
.form-submit {
    width: 200px;
    height: 50px;
    color: var(--color-text);
    float: right;
}

.form-submit p {
    font-size: 1.25em;
    letter-spacing: 0.1em;
    transition: color 0.4s ease, text-shadow 0.4s ease;
}

.form-submit:hover {
    cursor: pointer;
}

.form-submit:hover .border-rect-inner,
.form-submit:focus .border-rect-inner {
    stroke: url(#grad-v-glow);
}

.container-inner a {
    font-family: Jost, sans-serif;
    font-size: var(--fs-reg);
    letter-spacing: 0.1em;
    color: var(--color-text);
    font-weight: 400;
}

.container-inner a:hover,
#side-menu a:hover,
.right-nav a:hover,
.form-submit:hover > p,
.container-inner a:focus,
#side-menu a:focus,
.right-nav a:focus,
.form-submit:focus > p {
    color: #60c8ff !important;
    text-shadow: 0 0 2px var(--color-glow-2), 0 0 4px var(--color-glow-2),
        0 0 8px var(--color-glow-3), 0 0 16px var(--color-glow-4),
        0 0 32px var(--color-glow-4);
    mix-blend-mode: screen;
}

a.notification-btn:hover,
a.notification-btn:focus {
    color: var(--color-text) !important;
    text-shadow: none !important;
}

textarea {
    height: 200px;
    resize: none;
    overflow: initial !important;
    font-family: inherit;
}

.sm-form-group {
    gap: var(--xs-gap);
}

.sm-form-group .form-input {
    min-height: 30px;
    font-size: 0.8em;
    padding: 5px;
}

.sm-form-group button {
    color: var(--color-text);
    min-height: 30px;
    padding: 5px;
    width: 50%;
    align-self: center;
    background: var(--grad-blue-radial);
    border-radius: 4px;
    cursor: pointer;
}

/****************************************
* FOOTER
*****************************************/

.footer-content {
    width: 100%;
}

.footer-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.footer-section {
    width: 30%;
    gap: 10px;
    align-self: stretch;
}

.footer-content p {
    font-size: 0.8em;
}

.social-logos {
    display: flex;
    gap: 20px;
    flex-flow: wrap;
    justify-content: space-between;
}

.copyright {
    text-align: center;
    opacity: 0.5;
}

/****************************************
* SCROLLBAR
*****************************************/

::-webkit-scrollbar,
textarea::-webkit-scrollbar {
    background: transparent;
    width: 10px;
}

textarea::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track,
textarea::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb {
    background: var(--color-primary);
    border: 4px solid transparent;
    background-clip: padding-box;
}

::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb {
    border-radius: 5px;
}

.notification {
    gap: var(--md-gap);
    align-items: center;
    width: 100% !important;
    background: var(--grad-radial);
    padding: var(--lg-gap);
    text-align: center;
}

.notification-btn {
    background: var(--grad-blue-radial);
    padding: 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/****************************************
* VIDEO STYLES
*****************************************/

.video-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;
    margin: var(--lg-gap) 0;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/****************************************
* RESPONSIVE STYLES
*****************************************/

@media (max-width: 3840px) {
    #hero {
        background-position-y: 80%;
    }
}

@media (max-width: 2500px) {
    #hero {
        background-position-y: center;
    }
}

@media (max-width: 2000px) {
    body {
        background: var(--bg);
    }
}

@media (max-width: 1705px) {
    .no-js nav::before {
        background-image: url("../images/svgs/nav-1705.svg");
    }

    .no-js footer::before {
        background-image: url("../images/svgs/footer-1705.svg");
    }
}

@media (max-width: 1610px) {
    .no-js footer::before {
        background-image: url("../images/svgs/footer-1610.svg");
    }

    .no-js nav::before {
        background-image: url("../images/svgs/nav-1610.svg");
    }

    .col-third img {
        max-width: 80%;
    }

    .col-quater img {
        max-width: 100%;
    }

    .form-container {
        width: 60%;
    }
}

@media (max-width: 1515px) {
    .no-js nav::before {
        background-image: url("../images/svgs/nav-1515.svg");
    }

    .no-js footer::before {
        background-image: url("../images/svgs/footer-1515.svg");
    }
}

@media (max-width: 1420px) {
    .no-js nav::before {
        background-image: url("../images/svgs/nav-1420.svg");
    }

    .no-js footer::before {
        background-image: url("../images/svgs/footer-1420.svg");
    }

    #side-menu {
        width: 40%;
    }
}

@media (max-width: 1325px) {
    .no-js nav::before {
        background-image: url("../images/svgs/nav-1325.svg");
    }

    .no-js footer::before {
        background-image: url("../images/svgs/footer-1325.svg");
    }

    .profile-section {
        height: 550px;
    }

    .profile-section img {
        max-width: 35%;
        flex: 0 0 35%;
    }
}

@media (max-width: 1230px) {
    .no-js nav::before {
        background-image: url("../images/svgs/nav-1230.svg");
    }

    .no-js footer::before {
        background-image: url("../images/svgs/footer-1230.svg");
    }

    .profile-section {
        height: 600px;
        gap: var(--xl-gap);
    }

    .profile-section img {
        max-width: 40%;
        flex: 0 0 40%;
    }

    #orbit-anim {
        width: 70% !important;
    }
}

@media (max-width: 1135px) {
    .no-js nav::before {
        background-image: url("../images/svgs/nav-1135.svg");
    }

    .no-js footer::before {
        background-image: url("../images/svgs/footer-1135.svg");
    }

    .profile-section {
        height: 650px;
    }

    .profile-section img {
        max-width: 40%;
        flex: 0 0 40%;
    }
}

@media (max-width: 1040px) {
    .no-js nav::before {
        background-image: url("../images/svgs/nav-1040.svg");
    }

    .no-js footer::before {
        background-image: url("../images/svgs/footer-1040.svg");
    }
}

@media (max-width: 1024px) {
    #hero {
        background-position: 65%;
    }

    #hero-text {
        font-size: 1.25em;
        line-height: 3;
    }

    #side-menu {
        width: 50%;
    }

    .col-third img {
        max-width: 100%;
    }

    .profile-section {
        flex-direction: column;
        height: initial;
    }

    .profile-section:first-of-type {
        margin-bottom: var(--xxxl-gap);
    }

    .profile-section img {
        width: 50%;
        max-width: initial;
        flex: initial;
        align-self: center;
    }

    .profile-section h3,
    .profile-section h4 {
        text-align: center;
    }

    #orbit-anim {
        width: 80% !important;
    }

    .form-container {
        width: 100%;
    }
}

@media (max-width: 945px) {
    .no-js nav::before {
        background-image: url("../images/svgs/nav-945.svg");
    }

    .no-js footer::before {
        background-image: url("../images/svgs/footer-945.svg");
    }
}

@media (max-width: 896px) {
    #navbar {
        gap: initial;
    }

    .right-nav {
        display: none;
    }

    .left-nav {
        gap: initial;
        width: 100%;
    }

    .kt-title {
        margin: 0 auto;
    }

    #menu-btn {
        display: block;
    }

    #side-menu {
        width: 60%;
    }

    .timeline {
        width: 100%;
        position: relative;
        min-height: 534px;
    }

    .timeline-card {
        position: absolute;
        left: calc(50% - (var(--tl-card-width) / 2) + 10% * var(--offset));
        bottom: 0;
    }

    .timeline-card * {
        pointer-events: none;
    }

    [id^="tickGroup"] circle {
        --offset: 0;
        cx: calc(50% + 10% * var(--offset));
    }

    .no-js .timeline {
        flex-direction: column;
    }

    .no-js .timeline-card {
        position: initial;
        opacity: 1;
        transform: none;
        align-self: center;
    }

    .no-js .card-image {
        order: -1;
        transform: rotate(180deg);
    }

    .no-js .card-title {
        text-align: center;
    }

    .no-js #axis {
        display: none;
    }

    .footer-section {
        width: 40%;
    }
}

@media (max-width: 850px) {
    .no-js nav::before {
        background-image: url("../images/svgs/nav-850.svg");
    }

    .no-js footer::before {
        background-image: url("../images/svgs/footer-850.svg");
    }
}

@media (max-width: 768px) {
    .col-half,
    .col-third,
    .col-quater {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .separated-gap-sm {
        gap: var(--xl-gap);
    }

    .separated:not(:first-child)::before {
        content: "";
        position: absolute;
        top: calc(var(--xl-gap) * -0.5);
        left: 0;
        right: 0;
        height: 2px;
        background: var(--grad-fade-underline);
    }

    .col-third img,
    .col-quater img {
        max-width: 60%;
    }

    #hero .right-section img {
        max-width: 100%;
    }

    .accel-list p:first-of-type {
        min-width: 120px;
    }
}

@media (max-width: 755px) {
    :root {
        --container-margin: 200px;
    }

    .no-js nav::before {
        background-image: url("../images/svgs/nav-755.svg");
    }

    .no-js footer::before {
        background-image: url("../images/svgs/footer-755.svg");
    }

    .footer-content p {
        font-size: 0.7em;
    }
}

@media (max-width: 660px) {
    .no-js nav::before {
        background-image: url("../images/svgs/nav-660.svg");
    }

    .no-js footer::before {
        background-image: url("../images/svgs/footer-660.svg");
    }

    .social-logos {
        justify-content: flex-start;
    }

    .footer-section {
        width: 45%;
    }

    #hero {
        background-position: 53% center;
    }
}

@media (max-width: 600px) {
    .top-bar {
        font-size: 0.8em;
    }

    #hero {
        min-height: 680px;
        background-position: 65%;
    }

    .hero-text-section {
        top: 120px;
    }

    h1,
    h2,
    #hero-title {
        font-size: 30px;
        margin-bottom: var(--lg-gap);
    }

    #hero-text {
        font-size: 1em;
        line-height: 2.5;
    }

    .form-submit {
        display: block;
        margin: auto;
        float: none;
    }
}

@media (max-width: 565px) {
    .no-js nav::before {
        background-image: url("../images/svgs/nav-565.svg");
    }

    .no-js footer::before {
        background-image: url("../images/svgs/footer-565.svg");
    }

    #side-menu a {
        font-size: 1.25em;
    }
}

@media (max-width: 524px) {
    .top-bar {
        font-size: 0.6em;
    }

    :root {
        --container-margin: 160px;
    }

    .no-js footer::before {
        background-image: url("../images/svgs/footer-524s.svg");
        background-size: 100% 215px;
    }

    .sm-form-group button {
        width: 100%;
    }
}

@media (max-width: 480px) {
    h2 {
        font-size: 20px;
    }

    .timeline-card {
        width: 100%;
        left: calc(10% * var(--offset));
    }

    #orbit-anim {
        width: 100%;
    }

    .accel-list li {
        display: grid;
        grid-template-areas:
            "point item1"
            "item2 item2";
        grid-template-columns: auto 1fr;
        margin-bottom: var(--md-gap);
    }

    .accel-list li .point {
        grid-area: point;
    }

    .accel-list li p:first-of-type {
        grid-area: item1;
        font-weight: 400;
    }

    .accel-list li p:last-of-type {
        grid-area: item2;
    }
}

@media (max-width: 470px) {
    .no-js nav::before {
        background-image: url("../images/svgs/nav-470.svg");
    }

    .no-js footer::before {
        background-image: url("../images/svgs/footer-470.svg");
        background-size: 100% 215px;
    }

    .kt-logo {
        height: 45px;
        width: 69px;
    }

    .kt-title {
        height: 30px;
        width: 83px;
    }

    #side-menu {
        width: 65%;
    }

    #hero {
        background-position: 57%;
    }

    #hero-title {
        margin-bottom: var(--md-gap);
    }
}

@media (max-width: 390px) {
    .hero-text-section {
        top: 140px;
    }
}

@media (max-width: 375px) {
    .no-js nav::before {
        background-image: url("../images/svgs/nav-375.svg");
    }

    .no-js footer::before {
        background-image: url("../images/svgs/footer-375.svg");
        background-size: 100% 233px;
    }

    .kt-logo {
        height: 35px;
        height: 49px;
    }

    .kt-title {
        height: 25px;
        width: 70px;
    }

    #side-menu a {
        font-size: 1em;
    }

    #hero-text {
        font-size: 0.8em;
    }
}

@media (max-width: 280px) {
    body {
        min-width: 280px !important;
        overflow: inherit;
    }

    .kt-logo {
        height: 30px;
        width: 46px;
    }

    .kt-title {
        height: 20px;
        width: 56px;
    }

    #side-menu {
        width: 100%;
    }

    .no-js nav::before {
        background-image: url("../images/svgs/nav-280.svg");
    }

    .no-js footer::before {
        background-image: url("../images/svgs/footer-280.svg");
        background-size: 100% 253px;
    }
}

@media (max-height: 360px) {
    #side-menu a {
        font-size: 1em;
    }

    #side-menu ul {
        justify-content: center;
    }
}

@media (max-height: 280px) {
    #side-menu a {
        font-size: 0.75em;
    }

    #side-menu ul {
        gap: var(--sm-gap);
    }
}
