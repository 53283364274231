@font-face {
  font-family: KT Alpha;
  src: url("KT-Alpha-Regular.01c3869e.woff2") format("woff2");
  font-display: swap;
}

* {
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: var(--color-primary) transparent;
  margin: 0;
  padding: 0;
}

:root {
  --bg: #111116;
  --bgt: #0004;
  --color-grad-1: #212232;
  --color-grad-2: #171820;
  --color-text: white;
  --color-input-text: #b3b4cc;
  --color-primary: #68c4ff;
  --color-glow-1: #60c8ff;
  --color-glow-2: #52ebfb;
  --color-glow-3: #2dabff;
  --color-glow-4: #1874ff;
  --light-color: #b8bdf944;
  --color-underline: #282a38;
  --container-margin: 250px;
  --xxxl-gap: 160px;
  --xxl-gap: 80px;
  --xl-gap: 60px;
  --lg-gap: 40px;
  --md-gap: 20px;
  --sm-gap: 15px;
  --xs-gap: 10px;
  --blur-amount: 40px;
  --bullet-spacing: 2em;
  --fs-reg: .9375em;
  --fs-h1: 3.5em;
  --fs-h2: 2.5em;
  --tl-card-width: 300px;
  --grad-blue-glow: linear-gradient(to right, transparent 5%, var(--color-glow-3), var(--color-glow-1), var(--color-glow-3), transparent 95%);
  --grad-fade-underline: linear-gradient(to right, transparent 5%, #292a3d, transparent 95%);
  --grad-radial: radial-gradient(circle at right top, var(--color-grad-1) 0%, var(--color-grad-2) 80%);
  --grad-blue-radial: radial-gradient(circle at right top, var(--color-primary) 0%, #5ab1fb 80%);
  scroll-behavior: smooth;
}

html, body {
  width: 100%;
  overscroll-behavior: none;
  min-height: 100vh;
}

body {
  font-family: Jost, sans-serif;
  font-size: var(--fs-reg);
  letter-spacing: .1em;
  background: var(--bg);
  color: var(--color-text);
  justify-content: space-between;
  font-weight: 400;
  overflow-x: hidden;
}

#canvas {
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  display: block;
  position: fixed;
  inset: 0;
}

h1, h2 {
  margin-bottom: var(--lg-gap);
  font-weight: 500;
  line-height: 1.3;
}

h1 {
  font-size: var(--fs-h1);
  margin-bottom: var(--xl-gap);
}

h2 {
  font-size: var(--fs-h2);
  text-transform: uppercase;
}

h1, h2 {
  font-family: KT Alpha, Jost;
}

ul {
  list-style-type: none;
}

section li {
  margin-bottom: var(--sm-gap);
  align-items: flex-start;
  display: flex;
}

b {
  font-weight: 500;
}

em {
  color: var(--color-primary);
  font-weight: 500;
  font-style: initial;
}

a {
  text-decoration: none;
}

.small-font {
  font-size: .75em !important;
}

.center-text {
  text-align: center;
}

.container-inner li {
  align-items: flex-start;
  display: flex;
}

.container-inner li .point {
  flex-shrink: 0;
  margin-right: .5em;
}

.point {
  color: var(--color-glow-1);
  height: var(--bullet-spacing);
  width: var(--bullet-spacing);
  text-shadow: 0 0 2px var(--color-glow-2), 0 0 4px var(--color-glow-2), 0 0 8px var(--color-glow-3), 0 0 16px var(--color-glow-4), 0 0 24px var(--color-glow-4), 0 0 32px var(--color-glow-4), 0 0 64px var(--color-glow-4);
  font-weight: 400;
}

.ionicon {
  width: 24px;
  height: 24px;
  stroke: #fff !important;
}

.bs-icon {
  width: 24px;
  height: 24px;
}

button {
  background-color: #0000;
  border: none;
  margin: 0;
  padding: 0;
}

body, #side-menu, #side-menu ul, .timeline-card, .card-text-content, .form-group, .form-section, .sm-form-group, .notification, .footer-section {
  flex-direction: column;
  display: flex;
}

.container {
  width: 100%;
  margin: var(--container-margin) auto;
}

.container-inner {
  width: 80%;
  max-width: 1440px;
  margin: 0 auto;
}

.row {
  flex-wrap: wrap;
  display: flex;
}

.col-half {
  max-width: 50%;
  flex: 0 0 50%;
}

.col-third {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.col-quater {
  max-width: 25%;
  flex: 0 0 25%;
}

.col-half img {
  max-width: 100%;
}

.col-third img {
  max-width: 60%;
}

.col-quater img {
  max-width: 85%;
}

.center-items {
  align-items: center;
}

.mb-md {
  margin-bottom: var(--md-gap);
}

.mb-lg {
  margin-bottom: var(--lg-gap);
}

.mb-xl {
  margin-bottom: var(--xl-gap);
}

.mt-xxl {
  margin-top: var(--xxl-gap);
}

.mt-sm {
  margin-top: var(--sm-gap);
}

.mb-xxl {
  margin-bottom: var(--xxl-gap);
}

.separated {
  position: relative;
}

.separated-gap-sm {
  gap: 0;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.space-between {
  justify-content: space-between;
}

.no-display {
  display: none;
}

.visually-hidden {
  clip-path: inset(100%);
  height: 1px;
  white-space: nowrap;
  width: 1px;
  position: absolute;
  overflow: hidden;
}

#navbar, .no-js nav, footer, .no-js footer {
  width: 80%;
  max-width: 1440px;
  justify-content: space-between;
  align-items: center;
  gap: var(--lg-gap);
  z-index: 1;
  padding: 10px 20px;
  display: flex;
}

#navbar {
  z-index: 100;
  transition: top .3s;
  top: 0;
}

#navbar, .no-js nav, .no-js footer {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}

#navbar.hide {
  top: -92px;
}

.no-js nav:before, .no-js footer:before {
  content: "";
  z-index: -1;
  position: absolute;
  inset: 0 -20px -6px;
}

.no-js nav:before {
  background: url("nav-1800.ffa9ac15.svg") center / 100% 92px no-repeat;
}

footer {
  margin: var(--container-margin) auto 0;
  padding: 20px;
  position: relative;
}

.no-js footer {
  margin: 0;
  padding: 20px;
  position: relative;
}

.no-js footer:before {
  background: url("footer-1800.23906611.svg") center / 100% 197px no-repeat;
}

#nav-shape-container, #footer-shape-container {
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

#footer-shape {
  width: 100%;
  height: 100%;
}

.right-nav, .left-nav {
  align-items: center;
  gap: var(--lg-gap);
  display: flex;
}

.right-nav {
  margin-left: auto;
}

.left-nav {
  gap: 5px;
}

#navbar a {
  color: var(--color-text);
  gap: var(--lg-gap);
  transition: color .4s, text-shadow .4s;
}

.kt-logo {
  height: 60px;
  width: 92px;
}

.kt-title {
  height: 32px;
  width: 89px;
}

#menu-btn {
  cursor: pointer;
  padding: 15px;
  display: none;
}

.menu-checkbox {
  display: none;
}

#menu-checkbox:checked ~ #side-menu {
  opacity: 1;
  transition: right .3s ease-in-out, box-shadow 2s ease-in-out;
  right: 0;
  box-shadow: -10px 0 100px #459ff744;
}

#menu-checkbox ~ #side-menu {
  opacity: 1;
  right: -100%;
}

#menu-checkbox:checked ~ #menu-btn, #menu-checkbox:checked ~ #close-btn {
  --aria-expanded: true;
}

#menu-btn, #close-btn {
  content: attr(aria-label) " " attr(aria-expanded, var(--aria-expanded, false));
}

.top-bar {
  background: var(--grad-radial);
  text-align: center;
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  top: 96px;
  left: 0;
}

.top-bar:after {
  content: "";
  border: 1px solid var(--light-color);
  width: 100%;
  height: calc(100% + 2px);
  box-sizing: border-box;
  border-radius: 5px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.top-bar:hover:after {
  border: 1px solid var(--color-primary);
}

#side-menu {
  width: 30%;
  height: 100%;
  z-index: 999;
  will-change: scroll-position;
  opacity: 0;
  background: var(--color-grad-2);
  justify-content: space-evenly;
  gap: 20px;
  padding: 0 10%;
  transition: right .3s ease-in-out, box-shadow .3s ease-in-out;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -100%;
  overflow: hidden;
  box-shadow: -10px 0 100px #459ff700;
}

#side-menu-left, #side-menu-right {
  width: 7.5%;
  height: 100%;
  position: absolute;
}

#side-menu-left {
  left: 0;
}

#side-menu-right {
  right: 0;
  transform: scaleX(-1);
}

#close-btn {
  padding: 20px;
  position: absolute;
  top: 0;
  right: 10%;
}

#side-menu ul {
  gap: var(--md-gap);
  height: calc(100% - 148px);
  justify-content: space-between;
}

#side-menu li, #side-menu a {
  align-self: center;
}

#side-menu a {
  color: var(--color-text);
  font-size: 1.5em;
  text-decoration-line: none;
  transition: color .4s, text-shadow .4s;
}

#hero {
  background-color: var(--bg);
  min-height: 1080px;
  background-image: url("bg.cb2a3eb6.webp"), url("bg1.d1d3e485.png");
  background-position: center;
  background-size: cover;
  align-items: center;
  display: flex;
}

#hero .right-section img {
  max-width: 50%;
}

svg {
  overflow: visible;
}

#hero-text {
  font-size: 2em;
  line-height: 3;
}

.hero-text-section {
  width: 80%;
  flex-direction: column;
  position: absolute;
  top: 195px;
}

.border-bottom-glow {
  border-bottom: 2px solid #0000;
  border-image: var(--grad-blue-glow) 1;
}

.border-bottom-underline {
  border-bottom: 2px solid #0000;
  border-image: var(--grad-fade-underline) 1;
}

.accel-list p:first-of-type {
  min-width: 150px;
}

.card {
  background: var(--grad-radial);
  width: 60%;
  border-radius: 8px;
  margin: auto;
  padding: 10px;
  position: relative;
}

#orbit-anim {
  border-radius: 0 !important;
}

.col-half .card {
  margin-top: 60px;
}

.card-border {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.timeline {
  width: 100%;
  justify-content: space-around;
  align-items: stretch;
  display: flex;
  position: relative;
  overflow: hidden;
}

.no-js #tl-instruct {
  display: none;
}

.timeline-card {
  --offset: 0;
  width: var(--tl-card-width);
  transform-origin: bottom;
  outline: none;
  transition: transform .5s, left .5s, opacity .25s;
  bottom: 0;
}

.timeline-card, .card-text-content {
  gap: var(--md-gap);
}

.card-text-content {
  transition: opacity .5s;
}

.card-image {
  height: 300px;
  align-self: center;
  margin-top: auto;
}

.card-image img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.no-js .timeline-card {
  opacity: .5;
  transform: scale(.8);
}

.no-js .timeline-card:focus {
  opacity: 1;
  outline: none;
  transform: scale(1);
}

.dotted {
  stroke-linecap: round;
  stroke-dasharray: 8 10;
  stroke: #ffffff80 !important;
}

#arrow1, #arrow2, #arrow3 {
  opacity: 0;
}

.show-arrows #arrow1 {
  animation: 1s infinite flash;
}

.show-arrows #arrow2 {
  animation: 1s .25s infinite flash;
}

.show-arrows #arrow3 {
  animation: 1s .5s infinite flash;
}

@keyframes flash {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

#axis .domain {
  stroke-width: 0;
}

.tl-major-tick, .tl-inner-point, .tl-minor-tick, .tl-outer-point {
  stroke: #333449;
}

.tl-major-tick, .tl-inner-point {
  fill: #333449;
}

.tl-outer-point {
  fill: none;
  stroke-width: 2px;
}

.tl-minor-tick {
  stroke-width: 2px;
}

[id^="tickGroup"] {
  cursor: pointer;
}

[id^="tickGroup"] circle, [id^="tickGroup"].active-point circle {
  transition: cx .5s;
}

[id^="tickGroup"]:hover .tl-outer-point, [id^="tickGroup"].active-point .tl-outer-point {
  stroke: #5dbcff;
  filter: url("#glow1");
}

[id^="tickGroup"]:hover .tl-inner-point, [id^="tickGroup"].active-point .tl-inner-point {
  stroke: #5dbcff;
  fill: #5dbcff;
  filter: url("#glow1");
}

.profile-section {
  gap: var(--xxl-gap);
  height: 525px;
  align-items: stretch;
  display: flex;
}

.profile-section:first-of-type, .profile-section:nth-of-type(2) {
  margin-bottom: var(--xxl-gap);
}

.profile-section img {
  height: 100%;
  object-fit: cover;
  max-width: 25%;
  border-radius: 8px;
  flex: 0 0 25%;
}

.profile-section h3 {
  font-size: 1.75em;
}

.profile-details h4 {
  font-size: 1.25em;
  font-weight: 400;
}

#contact {
  scroll-margin-top: 150px;
}

.form-container {
  width: 50%;
  margin: 0 auto;
}

.form-group {
  gap: 10px;
  position: relative;
}

.form-group:last-of-type {
  margin-bottom: 40px;
}

label {
  cursor: pointer;
  font-size: 1.2em;
  font-weight: 400;
  display: block;
}

.form-section {
  gap: var(--lg-gap);
}

.form-input {
  min-height: 50px;
  letter-spacing: .1em;
  background: var(--grad-radial);
  width: 100%;
  caret-color: #fff;
  color: var(--color-input-text);
  border: none;
  border-bottom: 2px solid #0000;
  border-image: var(--grad-fade-underline) 1;
  border-radius: 4px;
  padding: 10px;
  font-size: 1em;
  transition: height .3s ease-in-out;
  overflow: hidden;
}

.form-input:focus {
  border-image: var(--grad-blue-glow) 1;
  outline: none;
}

.notification-btn, .form-submit {
  width: 200px;
  height: 50px;
  color: var(--color-text);
  float: right;
}

.form-submit p {
  letter-spacing: .1em;
  font-size: 1.25em;
  transition: color .4s, text-shadow .4s;
}

.form-submit:hover {
  cursor: pointer;
}

.form-submit:hover .border-rect-inner, .form-submit:focus .border-rect-inner {
  stroke: url("#grad-v-glow");
}

.container-inner a {
  font-family: Jost, sans-serif;
  font-size: var(--fs-reg);
  letter-spacing: .1em;
  color: var(--color-text);
  font-weight: 400;
}

.container-inner a:hover, #side-menu a:hover, .right-nav a:hover, .form-submit:hover > p, .container-inner a:focus, #side-menu a:focus, .right-nav a:focus, .form-submit:focus > p {
  text-shadow: 0 0 2px var(--color-glow-2), 0 0 4px var(--color-glow-2), 0 0 8px var(--color-glow-3), 0 0 16px var(--color-glow-4), 0 0 32px var(--color-glow-4);
  mix-blend-mode: screen;
  color: #60c8ff !important;
}

a.notification-btn:hover, a.notification-btn:focus {
  color: var(--color-text) !important;
  text-shadow: none !important;
}

textarea {
  height: 200px;
  resize: none;
  font-family: inherit;
  overflow: initial !important;
}

.sm-form-group {
  gap: var(--xs-gap);
}

.sm-form-group .form-input {
  min-height: 30px;
  padding: 5px;
  font-size: .8em;
}

.sm-form-group button {
  color: var(--color-text);
  min-height: 30px;
  width: 50%;
  background: var(--grad-blue-radial);
  cursor: pointer;
  border-radius: 4px;
  align-self: center;
  padding: 5px;
}

.footer-content {
  width: 100%;
}

.footer-flex {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.footer-section {
  width: 30%;
  align-self: stretch;
  gap: 10px;
}

.footer-content p {
  font-size: .8em;
}

.social-logos {
  flex-flow: wrap;
  justify-content: space-between;
  gap: 20px;
  display: flex;
}

.copyright {
  text-align: center;
  opacity: .5;
}

::-webkit-scrollbar, textarea::-webkit-scrollbar {
  width: 10px;
  background: none;
}

textarea::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track, textarea::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb, textarea::-webkit-scrollbar-thumb {
  background: var(--color-primary);
  background-clip: padding-box;
  border: 4px solid #0000;
}

::-webkit-scrollbar-thumb, textarea::-webkit-scrollbar-thumb {
  border-radius: 5px;
}

.notification {
  gap: var(--md-gap);
  background: var(--grad-radial);
  padding: var(--lg-gap);
  text-align: center;
  align-items: center;
  width: 100% !important;
}

.notification-btn {
  background: var(--grad-blue-radial);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
}

.video-container {
  width: 100%;
  margin: var(--lg-gap) 0;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
}

.video-container iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width <= 3840px) {
  #hero {
    background-position-y: 80%;
  }
}

@media (width <= 2500px) {
  #hero {
    background-position-y: center;
  }
}

@media (width <= 2000px) {
  body {
    background: var(--bg);
  }
}

@media (width <= 1705px) {
  .no-js nav:before {
    background-image: url("nav-1705.bb9290d9.svg");
  }

  .no-js footer:before {
    background-image: url("footer-1705.ced50aa5.svg");
  }
}

@media (width <= 1610px) {
  .no-js footer:before {
    background-image: url("footer-1610.6e59219b.svg");
  }

  .no-js nav:before {
    background-image: url("nav-1610.1474db8f.svg");
  }

  .col-third img {
    max-width: 80%;
  }

  .col-quater img {
    max-width: 100%;
  }

  .form-container {
    width: 60%;
  }
}

@media (width <= 1515px) {
  .no-js nav:before {
    background-image: url("nav-1515.1c38eb0b.svg");
  }

  .no-js footer:before {
    background-image: url("footer-1515.e1849d00.svg");
  }
}

@media (width <= 1420px) {
  .no-js nav:before {
    background-image: url("nav-1420.29e384d0.svg");
  }

  .no-js footer:before {
    background-image: url("footer-1420.e570a845.svg");
  }

  #side-menu {
    width: 40%;
  }
}

@media (width <= 1325px) {
  .no-js nav:before {
    background-image: url("nav-1325.f3a6bbf7.svg");
  }

  .no-js footer:before {
    background-image: url("footer-1325.c123a6ad.svg");
  }

  .profile-section {
    height: 550px;
  }

  .profile-section img {
    max-width: 35%;
    flex: 0 0 35%;
  }
}

@media (width <= 1230px) {
  .no-js nav:before {
    background-image: url("nav-1230.1eecb9a7.svg");
  }

  .no-js footer:before {
    background-image: url("footer-1230.a1f06c79.svg");
  }

  .profile-section {
    height: 600px;
    gap: var(--xl-gap);
  }

  .profile-section img {
    max-width: 40%;
    flex: 0 0 40%;
  }

  #orbit-anim {
    width: 70% !important;
  }
}

@media (width <= 1135px) {
  .no-js nav:before {
    background-image: url("nav-1135.fecbb772.svg");
  }

  .no-js footer:before {
    background-image: url("footer-1135.cbadf23e.svg");
  }

  .profile-section {
    height: 650px;
  }

  .profile-section img {
    max-width: 40%;
    flex: 0 0 40%;
  }
}

@media (width <= 1040px) {
  .no-js nav:before {
    background-image: url("nav-1040.394aaf59.svg");
  }

  .no-js footer:before {
    background-image: url("footer-1040.1f05539f.svg");
  }
}

@media (width <= 1024px) {
  #hero {
    background-position: 65%;
  }

  #hero-text {
    font-size: 1.25em;
    line-height: 3;
  }

  #side-menu {
    width: 50%;
  }

  .col-third img {
    max-width: 100%;
  }

  .profile-section {
    height: initial;
    flex-direction: column;
  }

  .profile-section:first-of-type {
    margin-bottom: var(--xxxl-gap);
  }

  .profile-section img {
    width: 50%;
    max-width: initial;
    flex: initial;
    align-self: center;
  }

  .profile-section h3, .profile-section h4 {
    text-align: center;
  }

  #orbit-anim {
    width: 80% !important;
  }

  .form-container {
    width: 100%;
  }
}

@media (width <= 945px) {
  .no-js nav:before {
    background-image: url("nav-945.1c6382bc.svg");
  }

  .no-js footer:before {
    background-image: url("footer-945.8e056c4b.svg");
  }
}

@media (width <= 896px) {
  #navbar {
    gap: initial;
  }

  .right-nav {
    display: none;
  }

  .left-nav {
    gap: initial;
    width: 100%;
  }

  .kt-title {
    margin: 0 auto;
  }

  #menu-btn {
    display: block;
  }

  #side-menu {
    width: 60%;
  }

  .timeline {
    width: 100%;
    min-height: 534px;
    position: relative;
  }

  .timeline-card {
    left: calc(50% - (var(--tl-card-width) / 2)  + 10% * var(--offset));
    position: absolute;
    bottom: 0;
  }

  .timeline-card * {
    pointer-events: none;
  }

  [id^="tickGroup"] circle {
    --offset: 0;
    cx: calc(50% + 10% * var(--offset));
  }

  .no-js .timeline {
    flex-direction: column;
  }

  .no-js .timeline-card {
    position: initial;
    opacity: 1;
    align-self: center;
    transform: none;
  }

  .no-js .card-image {
    order: -1;
    transform: rotate(180deg);
  }

  .no-js .card-title {
    text-align: center;
  }

  .no-js #axis {
    display: none;
  }

  .footer-section {
    width: 40%;
  }
}

@media (width <= 850px) {
  .no-js nav:before {
    background-image: url("nav-850.21633dd9.svg");
  }

  .no-js footer:before {
    background-image: url("footer-850.0f0d9f1d.svg");
  }
}

@media (width <= 768px) {
  .col-half, .col-third, .col-quater {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .separated-gap-sm {
    gap: var(--xl-gap);
  }

  .separated:not(:first-child):before {
    content: "";
    top: calc(var(--xl-gap) * -.5);
    height: 2px;
    background: var(--grad-fade-underline);
    position: absolute;
    left: 0;
    right: 0;
  }

  .col-third img, .col-quater img {
    max-width: 60%;
  }

  #hero .right-section img {
    max-width: 100%;
  }

  .accel-list p:first-of-type {
    min-width: 120px;
  }
}

@media (width <= 755px) {
  :root {
    --container-margin: 200px;
  }

  .no-js nav:before {
    background-image: url("nav-755.336a9d94.svg");
  }

  .no-js footer:before {
    background-image: url("footer-755.5dd7919e.svg");
  }

  .footer-content p {
    font-size: .7em;
  }
}

@media (width <= 660px) {
  .no-js nav:before {
    background-image: url("nav-660.6ab2d5ef.svg");
  }

  .no-js footer:before {
    background-image: url("footer-660.7d75a748.svg");
  }

  .social-logos {
    justify-content: flex-start;
  }

  .footer-section {
    width: 45%;
  }

  #hero {
    background-position: 53%;
  }
}

@media (width <= 600px) {
  .top-bar {
    font-size: .8em;
  }

  #hero {
    min-height: 680px;
    background-position: 65%;
  }

  .hero-text-section {
    top: 120px;
  }

  h1, h2, #hero-title {
    margin-bottom: var(--lg-gap);
    font-size: 30px;
  }

  #hero-text {
    font-size: 1em;
    line-height: 2.5;
  }

  .form-submit {
    float: none;
    margin: auto;
    display: block;
  }
}

@media (width <= 565px) {
  .no-js nav:before {
    background-image: url("nav-565.61fa9463.svg");
  }

  .no-js footer:before {
    background-image: url("footer-565.f33b4648.svg");
  }

  #side-menu a {
    font-size: 1.25em;
  }
}

@media (width <= 524px) {
  .top-bar {
    font-size: .6em;
  }

  :root {
    --container-margin: 160px;
  }

  .no-js footer:before {
    background-image: url("footer-524s.5efe08b5.svg");
    background-size: 100% 215px;
  }

  .sm-form-group button {
    width: 100%;
  }
}

@media (width <= 480px) {
  h2 {
    font-size: 20px;
  }

  .timeline-card {
    width: 100%;
    left: calc(10% * var(--offset));
  }

  #orbit-anim {
    width: 100%;
  }

  .accel-list li {
    margin-bottom: var(--md-gap);
    grid-template-columns: auto 1fr;
    grid-template-areas: "point item1"
                         "item2 item2";
    display: grid;
  }

  .accel-list li .point {
    grid-area: point;
  }

  .accel-list li p:first-of-type {
    grid-area: item1;
    font-weight: 400;
  }

  .accel-list li p:last-of-type {
    grid-area: item2;
  }
}

@media (width <= 470px) {
  .no-js nav:before {
    background-image: url("nav-470.6cb8056b.svg");
  }

  .no-js footer:before {
    background-image: url("footer-470.98349915.svg");
    background-size: 100% 215px;
  }

  .kt-logo {
    height: 45px;
    width: 69px;
  }

  .kt-title {
    height: 30px;
    width: 83px;
  }

  #side-menu {
    width: 65%;
  }

  #hero {
    background-position: 57%;
  }

  #hero-title {
    margin-bottom: var(--md-gap);
  }
}

@media (width <= 390px) {
  .hero-text-section {
    top: 140px;
  }
}

@media (width <= 375px) {
  .no-js nav:before {
    background-image: url("nav-375.1e65ed1e.svg");
  }

  .no-js footer:before {
    background-image: url("footer-375.cb6aee25.svg");
    background-size: 100% 233px;
  }

  .kt-logo {
    height: 35px;
    height: 49px;
  }

  .kt-title {
    height: 25px;
    width: 70px;
  }

  #side-menu a {
    font-size: 1em;
  }

  #hero-text {
    font-size: .8em;
  }
}

@media (width <= 280px) {
  body {
    overflow: inherit;
    min-width: 280px !important;
  }

  .kt-logo {
    height: 30px;
    width: 46px;
  }

  .kt-title {
    height: 20px;
    width: 56px;
  }

  #side-menu {
    width: 100%;
  }

  .no-js nav:before {
    background-image: url("nav-280.84135629.svg");
  }

  .no-js footer:before {
    background-image: url("footer-280.e62c7f2d.svg");
    background-size: 100% 253px;
  }
}

@media (height <= 360px) {
  #side-menu a {
    font-size: 1em;
  }

  #side-menu ul {
    justify-content: center;
  }
}

@media (height <= 280px) {
  #side-menu a {
    font-size: .75em;
  }

  #side-menu ul {
    gap: var(--sm-gap);
  }
}

/*# sourceMappingURL=index.03666807.css.map */
